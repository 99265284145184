import React, { useState, useEffect } from 'react';
import { getWeddingList } from '../../actions/weddingAction';
import LoadingSpinner from '../loader';
import WeddingBox from './weddingBox';
import { Box } from '@material-ui/core';
import { getLoggedInUserId } from '../../actions/authAction';
import { toast } from 'react-toastify';

const WeddingList = () => {
  const [weddings, setWeddings] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getList = async () => {
    try {
      setLoading(true);
      const loggedInUserId = getLoggedInUserId();
      console.log('Node id', loggedInUserId);
      const response = await getWeddingList({ node_id: loggedInUserId });
      console.log(response);
      setWeddings(response.events);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching wedding list:', error);
      toast.error('Error fetching wedding list');
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <h2>Weddings in your Family</h2>
      </div>
      {isLoading ? (
        <LoadingSpinner message="Loading Data For Wedding List" />
      ) : (
        <Box>
          {weddings?.length > 0 ? (
            weddings
              ?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
              .map((wedding) => <WeddingBox key={wedding._id} wedding={wedding} />)
          ) : (
            <p>No weddings available in your family</p>
          )}
        </Box>
      )}
      <div>
        <button
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            marginLeft: '15px',
          }}
          onClick={() => window.open(`/weddings/allWeddingDetail`, '_blank')}
        >
          Get all wedding detail
        </button>
      </div>
    </>
  );
};

export default WeddingList;
